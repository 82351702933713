import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// mui
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import FileOpen from '@mui/icons-material/FileOpen'

// src
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material'
import { useAppDispatch, useRootSelector } from '~/src/stores'
import { actions } from '~/src/stores/slices/auth'
import { ReauthDialog } from '~src/components/reauth-dialog'
import { ErrorInfoMessage } from '~src/functionals/error'
import { useUpdateUser } from '~src/hooks/update-user'

type ConfirmModalProps = {
  open: boolean;
  title: string;
  description: string;
  confirmText: string;
  closeText: string;
  onConfirm: () => void;
  onClose: () => void;
}

function ConfirmModal(props: ConfirmModalProps) {
  const { open, title, description, confirmText, closeText, onConfirm, onClose } = props

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{closeText}</Button>
        <Button onClick={onConfirm}>{confirmText}</Button>
      </DialogActions>
    </Dialog>
  )
}

export function MyAccount() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const navigate = useNavigate()
  const user = useRootSelector(state => state.auth.user)!
  const account = useRootSelector(state => state.auth.account)!

  const [allowExtraEmails, setAllowExtraEmails] = useState(account.allowExtraEmails)
  const { isValidating, mutate, errorInfo: err, reset } = useUpdateUser()

  const [reauthOpen, setReauthOpen] = useState(false)
  const [confirmType, setConfirmType] = useState<'signOut' | 'deleteUser' | 'none'>('none')
  const [confirmOpen, confirmTitle, confirmDescription, confirmText, closeText] = React.useMemo(() => {
    if (confirmType === 'signOut') {
      return [
        true,
        t('account-signout-confirm.title'),
        t('account-signout-confirm.description'),
        t('account-signout-confirm.confirm'),
        t('account-signout-confirm.close')
      ]
    } else if (confirmType === 'deleteUser') {
      return [
        true,
        t('account-delete-confirm.title'),
        t('account-delete-confirm.description'),
        t('account-delete-confirm.confirm'),
        t('account-delete-confirm.close')
      ]
    }
    return [false, '', '', '', '']
  }, [confirmType])

  // 名前
  const handleEditAccount = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    if (isValidating) {
      return
    }

    navigate('../edit')
  }

  // メールアドレス
  const handleChangeMail = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    if (isValidating) {
      return
    }

    navigate('../change-email')
  }

  // 最新情報などのEメールを受信
  const handleChangeAllowExtraEmails = (event: React.ChangeEvent<HTMLElement>, checked: boolean) => {
    event.preventDefault()

    if (isValidating) {
      return
    }

    const allowExtraEmails = checked
    setAllowExtraEmails(allowExtraEmails)

    mutate({
      allowExtraEmails
    }).catch(_ => { })
  }

  // パスワードを変更
  const handleChangePassword = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    if (isValidating) {
      return
    }

    navigate('../change-password')
  }

  // ログアウト
  const handleSignOut = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    if (isValidating) {
      return
    }

    setConfirmType('signOut')
  }

  // 退会する
  const handleWithdrawal = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    if (isValidating) {
      return
    }

    setReauthOpen(true)
  }

  const alertOpen = (!isValidating && err)

  // 実行[ConfirmModal]
  const handleSubmit = () => {
    if (confirmType === 'signOut') {
      dispatch(actions.signOut())
    } else if (confirmType === 'deleteUser') {
      const userId = account.id
      const uid = user!.uid

      // 退会処理
      dispatch(actions.deleteUserAccount({
        input: {
          userId,
          uid
        }
      }))
    }

    setConfirmType('none')
  }

  // キャンセル[ConfirmModal]
  const handleClose = () => {
    setConfirmType('none')
  }

  const handleReAuthSubmit = () => {
    setConfirmType('deleteUser')
  }

  useEffect(() => {
    const to = setTimeout(() => {
      // ユーザ情報再読み
      dispatch(actions.fetchUserAccount())
    }, 300)
    return () => clearTimeout(to)
  }, [])

  return (
    <>
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

        {/* アラート表示 */}
        {(alertOpen) &&
          <Stack sx={{ width: '100%', mb: 2 }}>
            <Alert severity="error" onClose={() => reset()}>
              {ErrorInfoMessage(err)}
            </Alert>
          </Stack>
        }

        <Grid container spacing={2}>
          <Grid item xs={12}>

            <List subheader={<ListSubheader>{t('account.header-login')}</ListSubheader>}>
              {/* メールアドレス */}
              <ListItemButton>
                <ListItemText primary={
                  <>
                    <Typography component="div" color="text.secondary" variant="caption">
                      {t('account.email')}
                    </Typography>
                    {user.email}
                    {!user.emailVerified && (
                      <Typography component="div" color="warning.main" variant="caption">
                        {t('account.email-is-not-verified')}
                      </Typography>
                    )}
                  </>
                } />
                {/*}
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <ArrowForwardIosIcon />
                </ListItemIcon>
                {*/}
              </ListItemButton>
              {/* パスワード */}
              <ListItemButton onClick={handleChangePassword}>
                <ListItemText primary={t('account.password-change')} />
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <ArrowForwardIosIcon />
                </ListItemIcon>
              </ListItemButton>
            </List>

            <Divider sx={{ my: 2 }} />

            <List subheader={<ListSubheader>{t('account.header-account')}</ListSubheader>}>
              {/* 名前 */}
              <ListItemButton onClick={handleEditAccount}>
                <ListItemText primary={
                  <>
                    <Typography component="div" color="text.secondary" variant="caption">
                      {t('account.name')}
                    </Typography>
                    {account.userName}
                  </>
                } />
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <ArrowForwardIosIcon />
                </ListItemIcon>
              </ListItemButton>
              {/* 広告のメール受信設定 */}
              {/*
              <ListItem secondaryAction={
                <Switch checked={allowExtraEmails}
                  disabled={isValidating}
                  onChange={handleChangeAllowExtraEmails}
                />
              }>
                <ListItemText primary={
                  <>
                    <Typography component="div" color="text.secondary" variant="button">
                      {t('account.input-allow-extra-emails')}
                    </Typography>
                  </>
                } />
              </ListItem>
              */}
            </List>

            <Divider sx={{ my: 2 }} />

            {/* ドキュメントリンク */}
            <Grid container spacing={2} sx={{ fontSize: '0.875rem', py: 1 }}>
              {/* サービス利用規約 */}
              <Grid item xs={12}>
                <Link color="inherit" target="_blank" rel="noopener"
                  href={t('agreement.goto-terms-of-service-url')}
                >
                  <FileOpen sx={{ mr: 1, fontSize: '1rem' }} />{t('agreement.goto-terms-of-service')}
                </Link>
              </Grid>
              {/* 個人情報保護方針 */}
              <Grid item xs={12}>
                <Link color="inherit" target="_blank" rel="noopener"
                  href={t('agreement.goto-privacy-policy-url')}
                >
                  <FileOpen sx={{ mr: 1, fontSize: '1rem' }} />{t('agreement.goto-privacy-policy')}
                </Link>
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            {/* ログアウト */}
            <Box sx={{ m: 2 }}>
              <Button size='large' fullWidth variant='outlined' onClick={handleSignOut}>
                {t('account.logout')}
              </Button>
            </Box>

            {/* 退会 */}
            <Box sx={{ m: 2, mt: 8 }}>
              <Button size='large' fullWidth variant='contained' color='error' onClick={handleWithdrawal}>
                {t('account.withdrawal')}
              </Button>
            </Box>

          </Grid>
        </Grid>

        <ConfirmModal
          open={confirmOpen}
          title={confirmTitle}
          description={confirmDescription}
          confirmText={confirmText}
          closeText={closeText}
          onConfirm={handleSubmit}
          onClose={handleClose}
        />

        {/* パスワード認証 */}
        <ReauthDialog
          open={reauthOpen}
          onClose={() => setReauthOpen(false)}
          onAccept={handleReAuthSubmit}
        />
      </Box >
    </>
  )
}
